import { RouteComponentProps } from "@reach/router"
import React from "react"
import { setUserData } from "../services/auth"
import { getData, getParameterByName, postData, STATE } from "../services/auth2"

export const APPLICATION_IDENTIFIER = process.env.MEMBERFUL_APPID

const loginUrl = `https://node-opcua.memberful.com/oauth?client_id=${APPLICATION_IDENTIFIER}&response_type=code&state=${STATE}`

console.log("APPLICATION_IDENTIFIER = ", APPLICATION_IDENTIFIER)
type AccessToken = string

async function _getToken(code: string): Promise<AccessToken> {
  const data = {
    client_id: APPLICATION_IDENTIFIER,
    grant_type: "authorization_code",
    code,
  }

  const data2 = await postData(
    "https://node-opcua.memberful.com/oauth/token",
    data
  )

  // console.log(data) // JSON data parsed by `data.json()` call
  const access_token = data2.access_token

  if (data2.error) {
    throw new Error("Invalid token: " + data2.error) //xx console.log("Invalid Grant = >", error, data.error_description)
  }
  return access_token
}
async function extractUserData(access_token: string) {
  try {
    /*
    access_token: "xmqf5ghH8ipiKufQxQEi5CAC"
    expires_in: 899
    refresh_token: "LnxfqppyFPHF3dyAfWr4yKEn"
    token_type: "bearer"

    // token need to be refreshed every 15 minutes
    */

    /*Once you've acquired an access token, make a GET request to the Memberful GraphQL API endpoint to retrieve information about the member.
    Endpoint URL:
    https://YOURSITE.memberful.com/api/graphql/member?query=GRAPHQL_QUERY
    Authorization header:
    Authorization: Bearer <access-token>
    */
    const GRAPHQL_QUERY = `{
    currentMember {
      address {
        city
        street
        postalCode
        country
      }
      creditCard {
        expMonth
        expYear
      }
      customField
      downloads {
        id
        name
      }
      email
      fullName
      id
      phoneNumber
      subscriptions {
        active
        expiresAt
        plan {
          id
          name
        }
      }
      unrestrictedAccess
    }
    }`.replace(/ /g, " ")

    const data3 = await getData(
      `https://node-opcua.memberful.com/api/graphql/member?query=${GRAPHQL_QUERY}`,
      {
        Authorization: "Bearer " + access_token,
        // Origin: "http://localhost",
        // "Allow-Origin": "*",
      }
    )
    //xx console.log(data3.data.currentMember)
    return data3.data.currentMember
  } catch (err) {
    return {}
  }
}

export class Login extends React.Component<RouteComponentProps> {
  componentDidMount() {
    const code = getParameterByName("code")
    const state = getParameterByName("state")
    if (!code) {
      window.location.assign(loginUrl)
    } else {
      ;(async () => {
        const access_token = await _getToken(code)

        const userData = await extractUserData(access_token)
        this.setState({
          userData,
        })
        setUserData(userData)
        window.location.assign("/")
      })()
    }
  }
  render() {
    return (
      <>
        <span>Logging in ...</span>
      </>
    )
  }
}
export default Login
