import React from "react"
import { Link, Router } from "@reach/router"
import Layout from "../components/Layout"
import { PrivateRoute } from "../components/PrivateRoute"
import Profile from "../components/Profile"
import Login from "../components/Login"
// import BookPage from "../template/BookPage"
const BookPage = React.lazy(() => import("../template/BookPage"))
import "../services/memberful"
import { Products } from "../components/Products"
import Row from "react-bootstrap/esm/Row"
import Col from "react-bootstrap/esm/Col"
import { Button } from "react-bootstrap"
import { navigate } from "gatsby"

const LazyComponent = ({ Component, ...props }) => (
  <React.Suspense fallback={"<p>Loading...</p>"}>
    <Component {...props} />
  </React.Suspense>
)

//https://aravindballa.com/writings/gatsby-single-page-app/

const App = () => (
  <Layout>
    <Router>
      <PrivateRoute path="/app/profile" component={Profile} />
      <Login path="/app/login" />
      <LazyComponent Component={BookPage} path="/book/:chapter/:section" />
      <Products path="/app/pricing" />
    </Router>
    <Row>
      <Col style={{ textAlign: "center" }}>
        Already a member ?{" "}
        <Button onClick={() => navigate("/app/login")}>Login Now</Button>
      </Col>
    </Row>
  </Layout>
)
export default App
