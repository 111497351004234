import { navigate, Router } from "@reach/router"
import React from "react"
import { isAuthenticated, isBrowser } from "../services/auth"

export const PrivateRoute = ({ component: Component, ...rest }) => {
  if (
    !isAuthenticated() &&
    isBrowser &&
    window.location.pathname !== `/login`
  ) {
    // If we’re not logged in, redirect to the home page.
    navigate(`/app/login`)
    return null
  }
  return (
    <Router>
      <Component {...rest} />
    </Router>
  )
}
